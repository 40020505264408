.product img {
    width: 100%;
    height: 200px !important;
    object-fit: contain;
    cursor: pointer;
}

.discount {
    position: absolute;
    top: 0;
    left: 0;
    background: #0f3460;
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 50px;
    color: #fff;
    margin: 10px;
}

.product {
    background: #fff;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 10px;
}

.product h3 {
    margin: 10px 0;
    font-weight: 500;
    font-size: 17px;
    cursor: pointer;
}

.product .rate i {
    font-size: 15px;
    color: #ffcd4e;
    margin: 5px 5px 5px 0;
}

.product .price {
    display: flex;
    justify-content: space-between;
    align-items:center;
    color: black;
}

.price h4 {
    margin: 20px 0 10px;
}

.price button {
    background: none;
    color: #0f3460;
    font-size: 20px;
    transition: 0.5s;
    border: 1px solid rgb(3 0 71 / 9%);
    width: 35px;
    height: 35px;
    border-radius: 5px;
}

.price button:hover {
    cursor: pointer;
    background-color: #0f3460;
    color: #fff;
}

button.add {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 50%;
}

.product-like {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    opacity: 0;
    transition: 0.5s;
}

.product-like label {
    background-color: #0f3460;
    padding: 1px 10px;
    font-size: 12px;
    color: white;
    border-radius: 50px;
}

.product-like ion-icon {
    font-size: 20px;
    margin: 10px 3px;
    cursor: pointer;
}

.product:hover .product-like {
    opacity: 1;
}