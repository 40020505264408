.marquee-text {
  font-size: 29px;
  font-weight: 600;
  margin: 60px 0px;
  color: #f02d34;
}
.marquee {
  position: relative;
  height: 400px;
  width: 100%;
  overflow-x: hidden;
}

.product-card {
  max-width: 250px;
  height: 450px;
  /* border: 1px solid #e1e1e1; */
  text-align: center;
  position: relative;
  background: #fff;
  overflow: hidden;
}

.product-card:hover {
  cursor: pointer;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 25s linear infinite;
  width: 180%;
}
.track:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.desc {
  position: absolute;
  right: 10%;
  bottom: 5%;
  width: 300px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  color: #324d67;
}
.desc p {
  color: #5f5f5f;
  font-weight: 100;
  text-align: end;
}
.desc h5 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  /* color: black; */
  align-self: flex-end;
}
.products-heading {
  text-align: center;
  margin: 40px 0px;
  color: #324d67;
}

.btn-container {
  width: 400px;
  margin: auto;
}
.btn {
  width: 50%;
  max-width: 200px;
  padding: 10px 12px;
  border-radius: 15px;
  border: none;
  font-size: 12px;
  margin-top: 10px;

  text-transform: uppercase;
  background-color: #f02d34;
  color: #fff;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.btn:hover {
  transform: scale(1.1, 1.1);
}
.product-detail-container {
  display: flex;
  gap: 40px;
  margin: 40px;
  margin-top: 60px;
  color: #324d67;
}

.product-detail-image {
  border-radius: 15px;
  background-color: #78909c;
  margin: 40px;
  width: 160px;
  height: 140px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.product-detail-image:hover {
  background-color: #ffffff;
}
.small-images-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.small-image {
  border-radius: 8px;
  background-color: #ffffff;
  width: 190px;
  height: 190px;
  cursor: pointer;
}

.reviews {
  color: #f02d34;
  margin-top: 10px;
  display: flex;
  gap: 5px;
  text-align: center;
}

.product-detail-desc h4 {
  margin-top: 10px;
}
.product-detail-desc p {
  margin-top: 5px;
}
.reviews p {
  color: #324d67;
  margin-top: 0px;
}

.product-detail-desc .buttons {
  display: flex;
  gap: 30px;
}
.buttons .add-to-cart {
  padding: 10px 20px;
  border: 1px solid #f02d34;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  color: #f02d34;
  cursor: pointer;
  width: 200px;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.buttons .add-to-cart:hover {
  transform: scale(1.1, 1.1);
}
.buttons .buy-now {
  padding: 10px 20px;
  border: 1px solid #f02d34;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  color: #f02d34;
  cursor: pointer;
  width: 200px;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.buttons .buy-now:hover {
  transform: scale(1.1, 1.1);
}

.maylike-products-wrapper {
  margin-top: 60px;
}
.maylike-products-wrapper h4 {
  text-align: center;
  margin: 30px;
  color: #324d67;

  font-size: 20px;
}
.maylike-products-container {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .product-detail-container {
    flex-wrap: wrap;
  }
  .product-detail-container
    .product-detail-image {
    width: 50px;
    height: 50px;
  }

  .buttons .add-to-cart {
    width: 150px;
  }
  .buttons .buy-now {
    width: 150px;
  }
  .product-detail-container {
    margin: 20px;
  }

  .item-desc .flex {
    width: 200px;
  }
  .top {
    flex-wrap: wrap;
    gap: 10px;
  }
  .item-desc .bottom {
    margin-top: 30px;
  }
  .flex h5 {
    font-size: 16px;
    color: #324d67;
  }
  .flex h4 {
    font-size: 16px;
    color: black;
  }

  .track {
    animation: marquee 20s linear infinite;
    width: 500%;
    white-space: nowrap;
    will-change: transform;
    position: absolute;
  }
  .btn-container {
    width: 300px;
    margin: auto;
  }
}

.product img {
  width: 100%;
  height: 200px !important;
  object-fit: contain;
  cursor: pointer;
}

.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #0f3460;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}

.product {
  background: #fff;
  padding: 12px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgba(71, 0, 39, 0.12) 4px 4px 6px;
  margin: 40px;
}

.product h3 {
  margin: 10px 0;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  font-family: "Times New Roman", Times, serif;
  text-align: justify;
}

.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
  align-items: center;
}

.product .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.price h4 {
  margin: 20px 0 10px;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}

.price button {
  background: none;
  color: #0f3460;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.price button:hover {
  cursor: pointer;
  background-color: #0f3460;
  color: #fff;
}

button.add {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 50%;
}

.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}

.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}

.product-like ion-icon {
  font-size: 20px;
  margin: 10px 3px;
  cursor: pointer;
}

.product:hover .product-like {
  opacity: 1;
}

@media (max-width: 600px) {
  .product {
    background: #fff;
    padding: 1px;
    position: relative;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
    margin: 11px;
    width: 41%;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 50%;
  }
  .product img {
    width: 100%;
    height: 100px !important;
    object-fit: contain;
    cursor: pointer;
  }

  .product .rate i {
    font-size: 10px;
    color: #ffcd4e;
    margin: 3px 3px 3px 0;
    align-items: center;
  }

  .product h3 {
    margin: 10px 0;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    font-family: "Times New Roman", Times, serif;
    text-align: justify;
  }
  .price h4 {
    margin: 20px 0 10px;
    font-size: 17px;
    font-family: "Times New Roman", Times, serif;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 50%;
  }
}
